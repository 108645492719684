export const useConfig = () => {
  const { NUXT_PUBLIC_API_PROTOCOL: protocol, NUXT_PUBLIC_API_BASE_URL: url } = useRuntimeConfig().public;

  const baseUrl = `${protocol}://${url}/middleware`;
  const headers = useRequestHeaders();

  return {
    baseUrl,
    headers,
  };
};
